import VCard from "vcard-creator";

export const generateVCard = async (links) => {
  const newVCard = new VCard();
  if (links.lastname || links.firstname) {
    newVCard.addName(links.lastname, links.firstname);
  }

  if (links.title) {
    newVCard.addJobtitle(links.title);
    newVCard.addRole(links.title);
  }

  if (links.email) {
    newVCard.addEmail(links.email);
  }

  if (links.phone_number) {
    newVCard.addPhoneNumber(links.phone_number);
  }

  if (links.website_urls.length > 0) {
    for (let website_url of links.website_urls) {
      newVCard.addURL(website_url);
    }
  }

  if (links.instagram_usernames > 0) {
    for (let instagram_username of links.instagram_usernames) {
      newVCard.addSocial(
        `https:/instagram.com/${instagram_username}`,
        "Instagram",
        instagram_username
      );
    }
  }

  if (links.twitter_usernames > 0) {
    for (let twitter_username of links.twiter_usernames) {
      newVCard.addSocial(
        `https:/twitter.com/${twitter_username}`,
        "Twitter",
        twitter_username
      );
    }
  }

  if (links.snapchat_usernames > 0) {
    for (let snapchat_username of links.snapchat_usernames) {
      newVCard.addSocial(
        `https://snapchat.com/add/${snapchat_username}`,
        "Snapchat",
        snapchat_username
      );
    }
  }

  if (links.tiktok_username > 0) {
    for (let tiktok_username of links.tiktok_usernames) {
      newVCard.addSocial(
        `https:/tiktok.com/@${tiktok_username}`,
        "TikTok",
        tiktok_username
      );
    }
  }

  if (links.facebook_url) {
    newVCard.addSocial(links.facebook_url, "Facebook", links.facebook_url);
  }

  if (links.youtube_url) {
    newVCard.addSocial(links.youtube_url, "Youtube", links.youtube_url);
  }

  if (links.linkedin_url > 0) {
    for (let linkedin_url of links.linkedin_urls) {
      newVCard.addSocial(linkedin_url, "LinkedIn", linkedin_url);
    }
  }

  if (links.whatsapp_number) {
    newVCard.addSocial(
      `https://wa.me/${links.whatsapp_number}`,
      "WhatsApp",
      links.whatsapp_number
    );
  }

  if (links.cashapp_username) {
    newVCard.addSocial(
      `https://cash.app/${links.cashapp_username}`,
      "CashApp",
      links.cashapp_username
    );
  }

  if (links.paypal_username) {
    newVCard.addSocial(
      `https://paypal.me/${links.paypal_username}`,
      "Paypal",
      links.paypal_username
    );
  }

  if (links.venmo_username) {
    newVCard.addSocial(
      `https://venmo.com/${links.venmo_username}`,
      "Venmo",
      links.venmo_username
    );
  }

  if (links.profile_photo) {
    await photoURIToBase64(links.profile_photo).then((blob) => {
      newVCard.addPhoto(blob, "JPEG");
    });
  }

  return newVCard.toString();
};

// Function to convert a photo URI to a Blob using fetch
async function photoURIToBase64(photoURI) {
  try {
    // Fetch the photo URI
    const response = await fetch(photoURI);

    // Check if the request was successful (status code 200)
    if (!response.ok) {
      throw new Error(`Error loading photo: ${response.statusText}`);
    }

    // Convert the response data to a base64-encoded string
    const bufferArray = await response.arrayBuffer();
    let TYPED_ARRAY = new Uint8Array(bufferArray);
    const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, "");

    let base64String = btoa(STRING_CHAR);

    console.log(base64String);
    return base64String;
  } catch (error) {
    console.error(`Error loading photo: ${error.message}`);
    throw error; // Rethrow the error for handling elsewhere if needed
  }
}

export const generatVCardForContact = async (contact) => {
  const newVCard = new VCard();
  if (contact.name.includes(" ")) {
    newVCard.addName(contact.name.split(" ")[1], contact.name.split(" ")[0]);
  } else {
    newVCard.addName(contact.name, "");
  }

  if (contact.email) newVCard.addEmail(contact.email);
  if (contact.phone) newVCard.addPhoneNumber(contact.phone);
  if (contact.notes) newVCard.addNote(contact.notes);

  return newVCard.toString();
};
